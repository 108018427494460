import WakiliChatImage from "../../img/Landing_WakiliChat.png";
import Diamond from "../../img/diamond.png";
import Database from "../../img/database.png";

export default function WakiliChat() {
  return (
    <section id="wakilichat" className="py-6 md:py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-stretch md:space-x-8">

          {/* Image Container - Visible on desktop, hidden on mobile */}
          <div className="hidden md:flex flex-1 md:w-1/2 justify-center items-stretch">
            <img
              src={WakiliChatImage}
              alt="Wakili Chat"
              className="w-full h-full object-contain rounded-2xl"
            />
          </div>

          {/* Text and Features Container */}
          <div className="flex-1 md:w-1/2 flex flex-col justify-between shadow-xs px-1 md:px-6">
            <div>
              <h3 className="text-center md:text-left text-xl lg:text-4xl font-medium text-gray-900">
                Wakili Chat
              </h3>
              
              {/* Mobile Image - only visible on mobile */}
              <div className="md:hidden mt-4 mb-6">
                <img
                  src={WakiliChatImage}
                  alt="Wakili Chat"
                  className="w-full h-auto object-contain rounded-xl"
                />
              </div>

              <p className="mt-3 text-[12px] text-gray-800 leading-loose">
                Wakili Chat is your AI-powered legal assistant, built to serve as your 
                <span className="font-semibold"> Co-pilot in legal research and case preparation.</span> 
                Whether you're formulating strategy, building a case, or establishing precedents, Wakili Chat delivers precise, contextually 
                relevant, and cited insights tailored to your needs.
              </p>
            </div>

            {/* Features Section */}
            <div className="flex flex-col sm:flex-row justify-between gap-6 mt-3">
              
              {/* Feature 1 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Diamond} alt="Kenyan Context" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto" />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Kenyan Context
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  Our AI model has been exclusively trained on Kenyan case law, ensuring all its outputs are locally relevant.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Database} alt="Downloadable Citations" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto" />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Downloadable Citations
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  All of Wakili Chat’s outputs are cited, ensuring the utmost accuracy and reliability.
                </p>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
