import { useFetchUsers } from "../../../../data/users/use-fetch-users";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faKey, faUser, faEllipsisV, faSort } from "@fortawesome/free-solid-svg-icons";

const Admin: React.FC = () => {
  const navigate = useNavigate();
  const { data: users, isLoading, error } = useFetchUsers();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [searchEmail, setSearchEmail] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterPlan, setFilterPlan] = useState('');
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  
  const usersPerPage = 6;


  // Function to handle sorting
  const handleSort = (field: string) => {
    setSortField(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Function to handle search and filter
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEmail(e.target.value);
  };

  // Function to filter and sort users
  const filteredSortedUsers = () => {
    let filteredUsers = users || [];

    // Filter by status
    if (filterStatus) {
      filteredUsers = filteredUsers.filter((user) => user.subscriptionStatus === filterStatus);
    }

    // Filter by plan
    if (filterPlan) {
      filteredUsers = filteredUsers.filter((user) => user.subscriptionPlan === filterPlan);
    }

    // Search by email
    if (searchEmail) {
      filteredUsers = filteredUsers.filter((user) =>
        user.email.toLowerCase().includes(searchEmail.toLowerCase())
      );
    }

    // Sorting logic
    if (sortField) {
      filteredUsers.sort((a, b) => {
        const aValue = a[sortField as keyof typeof a];
        const bValue = b[sortField as keyof typeof b];
        if (aValue === undefined || aValue === null) return 1; 
        if (bValue === undefined || bValue === null) return -1;

        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }

    return filteredUsers;
  };

  const handleReset = () => {
    setSearchEmail('');
    setFilterStatus('');
    setFilterPlan('');
    setSortField(null);
    setSortOrder('asc');
  };

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredSortedUsers().slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredSortedUsers().length / usersPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const toggleMenu = (userId: string) => {
    setActiveMenu(activeMenu === userId ? null : userId);
  };

  // Function to convert JSON data to CSV
  const exportToCSV = () => {
    if (!users || users.length === 0) return;

    const csvRows: string[] = [];

    // Define headers for CSV
    const headers = [
      "Name",
      "Email",
      "Subscription Status",
      "Joined",
      "Last Used",
      "WC",
      "CWMD",
      "WC SA",
      "CWMD SA",
      "Sem",
      "Key",
    ];
    csvRows.push(headers.join(","));

    // Add user data to CSV
    users.forEach((user) => {
      const row = [
        user.name,
        user.email,
        user.subscriptionStatus,
        `"${new Date(user.joined).toLocaleDateString("en-US")}"`, // Format date and enclose in quotes
        `"${user.lastUsed ? new Date(user.lastUsed).toLocaleDateString("en-US") : "N/A"}"`,
        user.nonSpecificChatMessages,
        user.specificChatMessages,
        user.nonSpecificChatMessagesSinceActivation,
        user.specificChatMessagesSinceActivation,
        user.semanticCount,
        user.keywordCount,
      ];
      csvRows.push(row.join(","));
    });

    // Create CSV file
    const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users_data.csv");
    document.body.appendChild(link);

    link.click();
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className=" bg-slate-50 overflow-scroll text-[11px] py-5">
      <div className="container mx-auto px-4">
        <div className="flex justify-end mb-4">
          {/* Export Button */}
          <button onClick={exportToCSV} className="bg-green-500 text-white py-2 px-4 rounded">
            Export as CSV
          </button>
        </div>

        {/* Search and Filter */}
        <div className="flex  justify-between space-x-4 mb-6">
          <div className ='flex space-x-4'>
          <input
            type="text"
            placeholder="Search by Email"
            value={searchEmail}
            onChange={handleSearch}
            className="border p-2 text-xs"
          />
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border p-2 text-xs"
          >
            <option value="">Filter by Subscription Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Trial">Trial</option>
          </select>
          <select
            value={filterPlan}
            onChange={(e) => setFilterPlan(e.target.value)}
            className="border p-2 text-xs"
          >
            <option value="">Filter by Subscription Plan</option>
            <option value="Silver">Silver</option>
            <option value="Gold">Gold</option>
            <option value="Platinum">Platinum</option>
          </select>
          </div>
         <button className='bg-blue-500 text-white py-3 px-4 md:px-9 rounded' onClick={handleReset}>
            Reset 
          </button>
        </div>

        <div className="overflow-x-scroll">
          <table className="min-w-full bg-white leading-8 table-auto">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-3 px-4 border-b">Email</th>
                <th className="py-3 px-4 border-b">Status</th>
                <th className="py-3 px-4 border-b">Plan</th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('joined')}>
                  Joined <FontAwesomeIcon icon={faSort} />
                </th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('lastUsed')}>
                  Last Used <FontAwesomeIcon icon={faSort} />
                </th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('nonSpecificChatMessages')}>
                  WC <FontAwesomeIcon icon={faSort} />
                </th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('specificChatMessages')}>
                  CWMD <FontAwesomeIcon icon={faSort} />
                </th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('nonSpecificChatMessagesSinceActivation')}>
                  WC SA <FontAwesomeIcon icon={faSort} />
                </th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('specificChatMessagesSinceActivation')}>
                  CWMD SA <FontAwesomeIcon icon={faSort} />
                </th>
                <th className="py-3 px-4 border-b cursor-pointer" onClick={() => handleSort('semanticCount')}>Sem <FontAwesomeIcon icon={faSort} /></th>
                <th className="py-3 px-4 border-b" onClick={() => handleSort('keywordCount')}>Key <FontAwesomeIcon icon={faSort} /></th>
                <th className="py-3 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-4 border-b">{user.email}</td>
                  <td className="py-2 px-4 border-b">{user.subscriptionStatus}</td>
                  <td className="py-2 px-4 border-b">{user.subscriptionPlan}</td>
                  <td className="py-2 px-4 border-b">{new Date(user.joined).toLocaleDateString()}</td>
                  <td className="py-2 px-4 border-b">{user.lastUsed ? new Date(user.lastUsed).toLocaleDateString() : 'N/A'}</td>
                  <td className="py-2 px-4 border-b">{user.nonSpecificChatMessages}</td>
                  <td className="py-2 px-4 border-b">{user.specificChatMessages}</td>
                  <td className="py-2 px-4 border-b">{user.nonSpecificChatMessagesSinceActivation}</td>
                  <td className="py-2 px-4 border-b">{user.specificChatMessagesSinceActivation}</td>
                  <td className="py-2 px-4 border-b">{user.semanticCount}</td>
                  <td className="py-2 px-4 border-b">{user.keywordCount}</td>
                  <td className="py-2 px-4 border-b relative" onClick={() => toggleMenu(user.id)}>
                    <button  className="text-gray-600 hover:text-gray-800">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    {activeMenu === user.id && (
                      <div className="absolute right-0 -top-16 w-64 text-gray-900 bg-white rounded-md shadow-lg z-10">
                        <button onClick={() => navigate(`/wakili/admin/users/${user.id}`)} className="block px-4 text-xs text-gray-700 hover:bg-gray-100 w-full text-left">
                          <FontAwesomeIcon icon={faUser} className="inline mr-2"/> View Profile
                        </button>
                        <button onClick={() => navigate(`/wakili/admin/users/${user.id}/permissions`)} className="block px-4  text-xs text-gray-700 hover:bg-gray-100 w-full text-left">
                          <FontAwesomeIcon icon={faKey} className="inline mr-2"/> Change Permissions
                        </button>
                        <button onClick={() => {/* Handle delete */}} className="block px-4  text-xs text-gray-700 hover:bg-gray-100 w-full text-left">
                          <FontAwesomeIcon icon={faTrash} className="inline mr-2"/>Delete User
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between mt-6">
          <button
            className="bg-blue-500 text-white py-3 px-4 rounded disabled:bg-blue-300"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="bg-blue-500 text-white py-3 px-4 rounded disabled:bg-blue-300"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
