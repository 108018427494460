import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddDocuments from "../../../app/Wakili/(components)/AddDocuments/page";
import DocRepository from "../../../app/Wakili/(components)/DocRepository/page";
import ChatMessages from "../../../app/Wakili/(components)/ChatMessages/page";
import ChatWithDocs from "../../../app/Wakili/(components)/ChatWithDocs/page";
import AddCollection from "../../../app/Wakili/(components)/AddCollection/page";
import Header from "../header";
import KenyaGazette from "../../../app/Wakili/(components)/Kenya Gazette/page";
import DocDrafter from "../../../app/Wakili/(components)/Doc Drafter/page";
import Admin from "../../../app/Wakili/(components)/Admin/page";
import User from "../../../app/Wakili/(components)/Admin/(components)/User/page";
import ChangeSubscription from "../../../app/Wakili/(components)/Admin/(components)/Permissions/page";
import Profile from "../../../app/Wakili/(components)/Profile/page";
import Trial2 from "../../../pages/Trial2";
import { useFetchSingleUser } from "../../../data/users/use-fetch-single-user";
import { LOGGED_USER } from "../../../data/constants";

type SubscriptionPlan = 'Trial' | 'Platinum' | 'Silver' | 'Gold';
type SubscriptionStatus = 'Active' | 'Inactive' | 'Trial';

interface User {
  subscriptionStatus: SubscriptionStatus;
  subscriptionPlan: SubscriptionPlan;
}

export default function MainBar(): React.ReactElement | null {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [showWakiliChat, setShowWakiliChat] = useState<boolean>(true);
  const [showChatWithDocs, setShowChatWithDocs] = useState<boolean>(true);
  const [showKenyaGazette, setShowKenyaGazette] = useState<boolean>(true);

  const userId = LOGGED_USER();
  const { data: user } = useFetchSingleUser();

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
  }, [userId, navigate]);

  useEffect(() => {
    if (user) {
      const isTrialOrPlatinum = user.subscriptionStatus === 'Trial' ||  user.subscriptionPlan === 'Platinum';
      const isTrialOrSilver = user.subscriptionStatus === 'Trial' || user.subscriptionPlan === 'Silver';
      const isTrialOrGold = user.subscriptionStatus === 'Trial' || user.subscriptionPlan === 'Gold';
      const isActive = user.subscriptionStatus !== 'Inactive';
      

      setShowWakiliChat(isActive && (isTrialOrPlatinum || isTrialOrSilver));
      setShowChatWithDocs(isActive && (isTrialOrPlatinum || isTrialOrGold));
      setShowKenyaGazette(isActive);
    }
  }, [user]);

  if (!userId) {
    return null;
  }

  const getHeaderInfo = (): { title: string; show: boolean } => {
    const path = location.pathname;
    
    if (path === "/wakili" || path.startsWith("/wakili/chat/")) {
      return { title: "Wakili Chat", show: false };
    } else if (path === "/wakili/chatWithDoc" || path.startsWith("/wakili/chatWithDoc/chat/")) {
      return { title: "Chat With My Documents", show: true };
    } else if (path === "/wakili/docRepo") {
      return { title: "My Documents", show: true };
    } else if (path === "/wakili/addDoc") {
      return { title: "Add A Document", show: true };
    } else if (path === "/wakili/kenyaGazette") {
      return { title: "Case Search", show: false };
    } else if (path === "/wakili/docDrafter") {
      return { title: "AI Document Drafter", show: false };
    } else if (path === "/wakili/admin") {
      return { title: "User Management", show: false };
    } else {
      return { title: "", show: false };
    }
  };

  const { title, show } = getHeaderInfo();

  return (
    <div className="ChatView flex-grow max-h-full w-5/6 relative">
      <Header title={title} showDownloadFilter={show} />
      <Routes>
        <Route path="" element={showWakiliChat ? <ChatMessages /> : <Trial2 />} />
        <Route path="chat/:chatId" element={showWakiliChat ? <ChatMessages /> : <Trial2 />} />
        <Route path="docRepo" element={<DocRepository />} />
        <Route path="chatWithDoc" element={showChatWithDocs ? <ChatWithDocs /> : <Trial2 />} />
        <Route path="chatWithDoc/chat/:chatId" element={showChatWithDocs ? <ChatWithDocs /> : <Trial2 />} />
        <Route path="addDoc" element={<AddDocuments />} />
        <Route path="addCollection" element={<AddCollection />} />
        <Route path="kenyaGazette" element={showKenyaGazette ? <KenyaGazette /> : <Trial2 />} />
        <Route path="docDrafter" element={<DocDrafter />} />
        <Route path="trial2" element={<Trial2 />} />
        <Route path="profile" element={<Profile />} />
        <Route path="admin" element={<Admin />} />
        <Route path="admin/users/:id" element={<User />} />
        <Route path="admin/users/:id/permissions" element={<ChangeSubscription />} />
      </Routes>
    </div>
  );
}