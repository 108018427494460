import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchSingleNonLoggedUser } from "../../../../../../data/users/use-fetch-single-user";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCreditCard, faSave, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

interface User {
  id: string;
  name: string;
  email: string;
  subscriptionStatus: 'Inactive' | 'Active' | 'Trial';
  joined: Date;
  lastUsed: Date | null;
  specificChatMessages: number;
  nonSpecificChatMessages: number;
}

const ChangeSubscription: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const { data: user, isLoading, error } = useFetchSingleNonLoggedUser(userId);
  const [newStatus, setNewStatus] = useState<'Inactive' | 'Active' | 'Trial' | ''>('');
  const [plan, setPlan] = useState<'Silver' | 'Gold' | 'Platinum' | ''>(''); // For plan selection
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>User not found</div>;

  const handleStatusChange = (status: 'Inactive' | 'Active' | 'Trial') => {
    setNewStatus(status);
    if (status !== 'Active') setPlan(''); // Reset plan if status is not Active
  };

  const handleSave = () => {
    if (newStatus === 'Active' && !plan) {
      alert('Please select a plan for Active status.');
      return;
    }
    if (newStatus && newStatus !== user.subscriptionStatus) {
      setShowConfirmation(true);
    }
  };

  const confirmStatusChange = async () => {
    if (newStatus) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/auth/updateSubscription/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: newStatus, plan }), // Send plan if Active
        });

        if (response.ok) {
          setShowConfirmation(false);
          navigate('/wakili/admin');
        } else {
          console.error('Failed to update subscription status');
        }
      } catch (error) {
        console.error('Error updating subscription status:', error);
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-center text-white">
          <h2 className="text-sm font-bold">Change Subscription Status</h2>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center mb-6">
            <FontAwesomeIcon icon={faUser} className="text-linearBlue2 mr-2" />
            <span className="text-xs font-semibold">{user.name}</span>
          </div>
          <div className="flex items-center mb-6">
            <FontAwesomeIcon icon={faCreditCard} className="text-linearBlue2 mr-2" />
            <span className="text-xs">Current Status: <span className='font-semibold'>{user.subscriptionStatus}</span></span>
          </div>
          <div className="space-y-4">
            {['Inactive', 'Active', 'Trial'].map((status) => (
              <div key={status} className="flex items-center">
                <input
                  type="radio"
                  id={status}
                  name="subscriptionStatus"
                  value={status}
                  checked={newStatus === status}
                  onChange={() => handleStatusChange(status as 'Inactive' | 'Active' | 'Trial')}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                />
                <label htmlFor={status} className="ml-2 block text-xs text-gray-900">
                  {status}
                </label>
              </div>
            ))}
          </div>

          {newStatus === 'Active' && (
            <div className="mt-4">
              <label className="block text-xs text-gray-900 font-semibold mb-2">Select Plan</label>
              <select
                value={plan}
                onChange={(e) => setPlan(e.target.value as 'Silver' | 'Gold' | 'Platinum')}
                className="w-full p-2 border border-gray-300 rounded-md text-xs">

                <option value="Silver">Silver</option>
                <option value="Gold">Gold</option>
                <option value="Platinum">Platinum</option>
              </select>
            </div>
          )}
        </div>
        <div className="px-4 py-3 flex justify-between items-center bg-gray-50 sm:px-6">
          <button
            onClick={handleSave}
            className="inline-flex justify-center items-center h-10 py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-gradient-to-r from-linearBlue1 to-linearBlue2 "
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save Changes
          </button>
         
          <button
            className="mt-4 py-2 px-4 hover:text-blue-500 duration-300"
            onClick={() => navigate(`/wakili/admin`)}
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="inline mr-2" /> Back
          </button>
         
        </div>
      </div>


      {showConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white px-5 py-10 rounded-lg shadow-xl">
            <h3 className="text-lg font-bold mb-2">Confirm Subscription Change</h3>
            <p>Are you sure you want to change the subscription status to <span className='font-semibold'>{newStatus}?</span> </p>
            {newStatus === 'Active' && <p>Selected Plan: <span className='font-semibold'>{plan}</span></p>}
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-3 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmStatusChange}
                className="px-4 py-3 rounded-lg bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-white"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeSubscription;
