import { Citations } from "../../../../data/chats/types";
import { useCollection } from "../../../../context/collectionContext";
import { useLocation } from "react-router-dom";
import Header from "../../../../components/layout/header/index"

interface CitationsViewProps {
  selectedCitation: Citations | null;
}

const CitationView: React.FC<CitationsViewProps> = ({ selectedCitation }) => {
  const { collectionValue } = useCollection();
  const location = useLocation();
  let context;

  if (location.pathname.startsWith('/citations/chatWithDoc/')){
    context = collectionValue.context;
  }else{
    context = "wakili";
  }
 

  return (
    <main className="relative w-full md:w-2/3 flex flex-col px-0 mt-4 md:mt-0 md:px-4">
  <div className="hidden md:block min-h-[10vh]">
    <Header />
  </div>
  <div className="ChatView_Citation_Citations py-1 px-2 flex-grow flex flex-col">
    {selectedCitation ? (
      <>
        <div className="flex flex-col md:flex-row justify-between items-center gap-2 md:gap-10">
          <p className="text-center md:text-left text-xs md:text-sm font-bold w-full sm:w-1/2 truncate">
            {selectedCitation.file_name}
          </p>
          <button className=" text-center md:text-right text-xs md:text-sm font-bold border rounded-3xl py-2 px-3 hover:bg-linearBlue2 transition duration-300">
            <a
              href={`https://bayesnet-s3-bucket.s3.us-east-1.amazonaws.com/${context}/${selectedCitation.file_name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download the whole document
            </a>
          </button>
        </div>

        <div className="mt-3 flex-grow">
          <iframe
            src={selectedCitation.file_path}
            title={selectedCitation.file_name}
            className="h-full w-full border-none p-0"
            style={{ zoom: "100%" }}
          ></iframe>
        </div>
      </>
    ) : (
      <p className="text-center font-bold text-sm mt-8">
        This message has no citation.
      </p>
    )}
  </div>
</main>

  );
};

export default CitationView;
