import React, { useState, useEffect } from 'react';
import { getTransactionStatus } from '../../data/pesapal/api';
import { Box, Typography, Paper, Grid, Button, CircularProgress } from '@mui/material';

interface TransactionStatus {
  payment_method: string;
  amount: number;
  payment_status_description: string;
  message: string;
  confirmation_code: string;
  merchant_reference: string;
}

export default function Payment(): JSX.Element {
  const [status, setStatus] = useState<TransactionStatus | string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTransactionStatus = async (): Promise<void> => {
      try {
        setLoading(true);
        const result = await getTransactionStatus();
        setStatus(result);
        console.log('the status is===>', result);
      } catch (err) {
        setStatus('Error fetching transaction status.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionStatus();
  }, []);

  return (
    <Box sx={{ padding: 4, maxWidth: '800px', margin: 'auto' }}>
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Checking transaction status...
          </Typography>
        </Box>
      ) : typeof status === 'string' ? (
        <Paper sx={{ padding: 4, textAlign: 'center', backgroundColor: '#fdecea' }}>
          <Typography variant="h6" color="error">
            {status}
          </Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.location.href = '/'}>
            Retry
          </Button>
        </Paper>
      ) : status ? (
        <Paper sx={{ padding: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Transaction Status
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Payment Method:</strong> {status.payment_method || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Amount:</strong> KES {status.amount}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Status Description:</strong> {status.payment_status_description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Message:</strong> {status.message}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Confirmation Code:</strong> {status.confirmation_code || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Merchant Reference:</strong> {status.merchant_reference || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.location.href = '/wakili'}>
                Back to Lawlyfy
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
    </Box>
  );
}