
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import { ChatProvider } from "./context/chatContext";
import { CitationsContextProvider } from "./context/citationsContext";
import { InputProvider } from "./context/inputContext";
import { CollectionProvider } from "./context/collectionContext";
import { NotificationProvider } from "./context/notificationContext";

import Login from "./app/Login/page";
import Register from "./app/Register/page";
import VerifyEmailAlert from "./app/Email/VerifyEmailAlert";
import VerifyEmail from "./app/Email/VerifyEmail";
import Trial from "./pages/Trial";
import Citation from "./app/Citation/page";
import AddDocuments from "./app/Wakili/(components)/AddDocuments/page";
import DocRepository from "./app/Wakili/(components)/DocRepository/page";
import DocDrafter from "./app/Wakili/(components)/Doc Drafter/page";
import CitationRepo from "./components/CitationRepo";
import ForgetPassword from "./app/Password/ForgetPassword/page";
import ResetPassword from "./app/Password/ResetPassword/page";
import Trial2 from "./pages/Trial2";
import Home from "./app/page";
import WakiliChat from "./app/Wakili/(components)/WakiliChat/page";
import Wakili from "./app/Wakili/page";
import ChatMessages from "./app/Wakili/(components)/ChatMessages/page";
import AddCollection from "./app/Wakili/(components)/AddCollection/page";
import KenyaGazette from "./app/Wakili/(components)/Kenya Gazette/page";
import Admin from "./app/Wakili/(components)/Admin/page";
import User from "./app/Wakili/(components)/Admin/(components)/User/page";
import ChangeSubscription from "./app/Wakili/(components)/Admin/(components)/Permissions/page";
import Checkout from "./app/Checkout/page";
import Payment from "./app/Payment/page";
import Profile from "./app/Wakili/(components)/Profile/page";
import Pricing from "./app/Pricing/page";



const App = () => {
  return (
    <div>
       <NotificationProvider>
      <AuthProvider>
        <ChatProvider>
          <CitationsContextProvider>
            <InputProvider>
              <CollectionProvider>
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/payment/complete" element={<Payment />} />
                  <Route
                    path="/accountRegAlert"
                    element={<VerifyEmailAlert />}
                  />
                  <Route
                    path="/verifyEmail/:VerificationToken"
                    element={<VerifyEmail />}
                  />
                  <Route path="/forgetPassword" element={<ForgetPassword />} />
                  <Route
                    path="/resetPassword/:token"
                    element={<ResetPassword />}
                  />

                
                  <Route path="/trial" element={<Trial />} />


                  <Route
                    path="/citations/:chatId/:messageId"
                    element={
                      <Citation />
                    }
                  />

                  <Route
                    path="/citations/chatWithDoc/:chatId/:messageId"
                    element={
                      <Citation />
                    }
                  />


                  <Route
                    path="/wakili"
                    element={
                      <Wakili />
                    }
                  >

                    <Route
                      index
                      element={
                        <WakiliChat />
                      }
                    />

                  <Route
                      path="trial2"
                      element={
                        <Trial2 />
                      }
                    />

                    <Route
                      path="chat/:chatId"
                      element={
                        <ChatMessages />
                      }
                    />

                    <Route
                      path="chatWithDoc"
                      element={
                        <WakiliChat />
                      }
                    />

                    <Route
                      path="chatWithDoc/chat/:chatId"
                      element={
                        <ChatMessages />
                      }
                    />

                    <Route
                      path="addDoc"
                      element={
                        <AddDocuments />
                      }
                    />

                    <Route
                      path="addCollection"
                      element={
                        <AddCollection />
                      }
                    />
                  

                    <Route
                      path="docRepo"
                      element={
                        <DocRepository />
                      }
                    />
                    <Route
                      path="docDrafter"
                      element={
                        <DocDrafter />
                      }
                    />

                    <Route
                      path="kenyaGazette"
                      element={
                        <KenyaGazette />
                      }
                    />

                    <Route
                      path="kenyaGazette/:filesize"
                      element={
                        <KenyaGazette />
                      }
                    />
                    <Route
                      path="citationRepo"
                      element={
                        <CitationRepo />
                      }
                    />
                     <Route
                      path="admin"
                      element={
                        <Admin />
                      }
                    />

                    <Route
                      path="profile"
                      element={
                        <Profile />
                      }
                    />
                    <Route
                      path="admin/users/:userId"
                      element={
                        <User />
                      }
                    />

                    <Route
                      path="admin/users/:userId/permissions"
                      element={
                        <ChangeSubscription />
                      }
                    />

                    
                  </Route>
                </Routes>
              </CollectionProvider>
            </InputProvider>
          </CitationsContextProvider>
        </ChatProvider>
      </AuthProvider>
      </NotificationProvider>
    </div>
  );
};

export default App;
