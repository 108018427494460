import { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { validationSchema } from "./validation";
import { useNavigate } from "react-router-dom";
import { REACT_APP_BACKEND_URL } from "../../../data/constants";


export const useRegisterForm = () => {
    const navigate = useNavigate();
  const [err, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      PhoneNumber: "",
      Password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await axios.post(
          `${REACT_APP_BACKEND_URL}/v1/auth/register`,
          values
        );
       
        navigate("/accountRegAlert");
      } catch (error: any) {
        setError(error.response?.data || "An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    },
  });

  return { formik, err, loading };
};
