import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { REACT_APP_BACKEND_URL } from "../../../../data/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faSpinner, faFile, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "../../../../context/collectionContext";
import { useNotification } from "../../../../context/notificationContext";
import { useNavigate } from 'react-router-dom';

const AddDocuments: React.FC = () => {
  const { collectionValue } = useCollection();
  const { setNotification } = useNotification();
  const navigate = useNavigate();

  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [successfulUploads, setSuccessfulUploads] = useState<{ [key: string]: boolean }>({});
  const [llmUploads, setLlmUploads] = useState<{ [key: string]: boolean }>({});
  const [llmError, setLlmError] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    // Function to get the user ID when the component is mounted
    const getUserId = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage("User is not logged in.");
        return;
      }



      try {
        const response = await axios.get(`${REACT_APP_BACKEND_URL}/v1/auth/users`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setUserId(response.data.id);
      } catch (error) {
        console.log('Error fetching user ID:', error);
        setErrorMessage('Failed to retrieve user information. Please try again later.');
      }
    };

    getUserId();
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      const validFiles = newFiles.filter(file => file.type === "application/pdf");
      if (files.length + validFiles.length > 5) {
        setErrorMessage("You can upload a maximum of 5 files.");
        return;
      }
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
      setErrorMessage(null);
    }
  };

  const handleRemoveFile = (indexToRemove: number) => {
    setFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const uploadFile = async (file: File, userId: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("context", userId);
    formData.append("collectionId", collectionValue._id);
    formData.append("collectionName", collectionValue.name);

    try {
      // Upload to the main server
      await axios.post(`${REACT_APP_BACKEND_URL}/v1/document/addDocument`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total != null) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(prev => ({ ...prev, [file.name]: percentCompleted }));
          }
        }
      });

      setSuccessfulUploads(prev => ({ ...prev, [file.name]: true }));

      // Now attempt to upload to LLM
      try {
        await axios.post(`https://staging-bayesnet.bayesconsultants.com/api/wakili/upload`, {
          context: `${userId}_${collectionValue.name}`,
          filename: file.name,
        });

        setLlmUploads(prev => ({ ...prev, [file.name]: true }));
      } catch (llmError) {
        setLlmUploads(prev => ({ ...prev, [file.name]: false }));
        //setLlmError(`Failed to upload to LLM: ${file.name}. Try again later.`);
        //console.log(`Failed to upload to LLM: ${file.name}`, llmError);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusCode = error.response?.status;
        const responseData = error.response?.data;

        if (statusCode === 401) {
          setErrorMessage(responseData || 'Unauthorized');
        } else if (statusCode === 500) {
          setErrorMessage(responseData || "Please ensure that the Context is Set");
        } else {
          setErrorMessage(responseData || 'Internal server error');
        }
      } else {
        setErrorMessage(`Failed to upload document: ${file.name}`);
      }
      setSuccessfulUploads(prev => ({ ...prev, [file.name]: false }));
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (files.length === 0) {
      setErrorMessage("No documents uploaded");
      return;
    }

    if (!userId) {
      setErrorMessage("Please log in to upload documents.");
      return;
    }

    setLoading(true);
    setSuccessfulUploads({});
    setLlmUploads({});
    setLlmError(null);

    setNotification('Uploads are in progress. This might take a while. You can continue exploring other features in the meantime!', 'pending');

    const uploadPromises = files.map(file => uploadFile(file, userId));
    Promise.all(uploadPromises)
      .then(() => {
        setLoading(false);
        const allSuccessful = files.every(file => successfulUploads[file.name] && llmUploads[file.name]);

        if (allSuccessful) {
          setNotification('File(s) successfully uploaded!', 'success');
          navigate('/wakili/docRepo');
        } else {
          setNotification('Failed to upload to the LLM. Try again later.', 'failure');
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error in upload process:", error);
        setNotification('An error occurred during the upload process.', 'failure');
      });
  };

  return (
    <main className="ChatView_Section">
      <div className="mx-auto w-[90vw] md:w-[30vw] text-sm h-full overflow-scroll">
        <div className="Notifications_Container text-xs">
          {errorMessage && (
            <div className="Error_Container">{errorMessage}</div>
          )}
          {llmError && (
            <div className="Error_Container">{llmError}</div>
          )}
        </div>

        <div className="bg-gray-50/80 h-full lg:min-h-[80vh] overflow-y-scroll rounded-2xl shadow-lg p-8 m-auto flex flex-col justify-between">
          <div className="text-center mb-6 text-xs">
            <h3 className="text-xl font-bold text-gray-800 mb-4">{`Upload Your Documents to ${collectionValue.name} Collection`}</h3>
            <p className="text-gray-600">
              Browse or drag and drop up to <span className="font-semibold text-blue-600">5 PDF</span> documents, each with a maximum size of <span className="font-semibold text-blue-600">7 MB</span>.
            </p>


          </div>

          <form onSubmit={handleSubmit} className="text-center lg:pt-12 flex-grow flex flex-col justify-between">
            <label htmlFor="file-upload" className="cursor-pointer">
              <div className="flex items-center justify-center w-16 h-16 border-2 border-dashed border-gray-300 rounded-full mx-auto mb-6">
                <FontAwesomeIcon icon={faUpload} className="text-gray-500 text-2xl" />
              </div>
              <input
                id="file-upload"
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                className="hidden"
                multiple
              />
            </label>

            {files.length > 0 && (
              <div className="mb-4">
                {files.map((file, index) => (
                  <div key={index} className="w-full flex items-center gap-6 bg-white shadow-sm rounded-md px-2 py-4 mb-4">
                    <div>
                      <FontAwesomeIcon icon={faFile} className='text-red-500' />
                    </div>
                    <div className='w-[75%]'>
                      <div className="overflow-x-hidden line-clamp-1 font-semibold text-left text-black mb-3">
                        {file.name}
                        {successfulUploads[file.name] && <FontAwesomeIcon icon={faCheck} className="ml-2 text-green-500" />}
                      </div>
                      {loading && (
                        <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                          <div
                            className="bg-blue-600 h-1.5 rounded-full"
                            style={{ width: `${uploadProgress[file.name] || 0}%` }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex-shrink-0">
                      <button type="button" onClick={() => handleRemoveFile(index)} className="text-red-500">
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div>
              <button
                type="submit"
                disabled={loading}
                className={`p-3 w-full ${loading ? 'bg-blue-500/50' : 'bg-blue-500'} text-white rounded-lg font-semibold`}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default AddDocuments;
