import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { toast } from 'react-toastify';
import { REACT_APP_BACKEND_URL } from "../../../../../../data/constants"
import OverlayComponent from "../Keyword/(components)/pdf";

interface Citation {
  file_name: string;
  file_path: string;
  file_size: number;
}

interface CaselawDocument {
  "Case Number": string;
  "Parties": string;
  "Date Delivered": string;
  "Case Class": string;
  Court: string;
  "Case Action": string;
  "Judge(s)": string;
  Citation: string;
  Disclaimer: string;
  case_id: string;
  html_file: string;
  file_name: string;
}

const SemanticAI: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [citations, setCitations] = useState<Citation[]>([]);
  const [caseDetails, setCaseDetails] = useState<CaselawDocument[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCitation, setSelectedCitation] = useState<Citation | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingPDFs, setLoadingPDFs] = useState<Record<string, boolean>>({});
  const [selectedPdfUrl, setSelectedPdfUrl] = useState<string | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const ITEMS_PER_PAGE = 3;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://staging-bayesnet.bayesconsultants.com/api/wakili_semantic_search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: 1,
            prompt,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setCitations(data.citation);

      // Fetch case details
      const caseDetailsResponse = await fetch(`${REACT_APP_BACKEND_URL}/v1/kenyaCaseLaw/getCaseDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ citations: data.citation }),
      });

      if (!caseDetailsResponse.ok) {
        throw new Error("Failed to fetch case details");
      }

      const caseDetailsData = await caseDetailsResponse.json();
      setCaseDetails(caseDetailsData.caseDetails);

      setCurrentPage(0);

      const token = localStorage.getItem('token'); 

      //add the get here now 
      await fetch (`${REACT_APP_BACKEND_URL}/v1/message/updateSemanticCount`, {
        method: 'PUT', 
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json'  
        }
      })

    } catch (err: any) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const handleCitationClick = (citation: Citation) => {
    setSelectedCitation(citation);
    setIsModalOpen(true);
  };

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentCaseDetails = caseDetails.slice(offset, offset + ITEMS_PER_PAGE);
  const pageCount = Math.ceil(caseDetails.length / ITEMS_PER_PAGE);

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md overflow-y-scroll">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <textarea
            className="w-full border p-2 rounded text-xs"
            rows={5}
            placeholder="Describe what you are looking for and our AI assistant will help you source relevant cases. Remember to be as detailed as possible to ensure the best results. You do not have to use key words."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="flex justify-center md:justify-end">
          <button
            type="submit"
            className="text-xs Medium_Shade text-white font-semibold py-2 px-4 md:px-8 rounded-full shadow-lg flex items-center transition-all duration-300"
            disabled={loading}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            ) : (
              <>
                Search <FontAwesomeIcon icon={faSearch} className="ml-2" />
              </>
            )}
          </button>
        </div>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {caseDetails.length > 0 && (
        <div className="mt-6 bg-white p-4 rounded-lg shadow-md text-xs text-gray-500 font-semibold leading-6">
          <p className="font-bold mb-2"> {caseDetails.length} Search results</p>
          <ul>
            {currentCaseDetails.map((caseData: CaselawDocument, index: number) => (
              <li key={index} className="mb-4">
                <div className="bg-white rounded-md shadow leading-8">
                  <div className="mb-1 rounded-md Lighter_Shade py-2 text-center font-semibold">
                    {caseData["Citation"]}
                  </div>
                  <div className="p-4">
                    <div className="mb-1">
                      <span className="font-bold">Case Number:</span>{" "}
                      {caseData["Case Number"]}
                    </div>
                    <div className="mb-1">
                      <span className="font-bold">Date Delivered:</span>{" "}
                      {caseData["Date Delivered"]}
                    </div>
                    <div className="mb-1">
                      <span className="font-bold">Court:</span> {caseData["Court"]}
                    </div>
                    <div className="mb-1">
                      <span className="font-bold">Parties:</span> {caseData["Parties"]}
                    </div>
                    <div className="mb-1">
                      <span className="font-bold">Judge(s):</span> {caseData["Judge(s)"]}
                    </div>
                    <div className="mb-1">
                      <span className="font-bold">Citation:</span> {caseData["Citation"]}
                    </div>

                    <div className="flex justify-end">
                      <button
                        onClick={async () => {
                          const fileName = caseData?.file_name;
                          if (fileName) {
                            setLoadingPDFs(prev => ({ ...prev, [fileName]: true }));
                            setError(null);
                            const pdfUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/document/getPdf?fileName=${encodeURIComponent(fileName)}`;
                            try {
                              const response = await fetch(pdfUrl);
                              if (!response.ok) throw new Error('Failed to load PDF');
                              setSelectedPdfUrl(pdfUrl);
                              setIsOverlayOpen(true);
                            } catch (err) {
                              const errorMessage = 'Failed to load PDF. Please try again.';
                              setError(errorMessage);
                              toast.error(errorMessage);
                            } finally {
                              setLoadingPDFs(prev => ({ ...prev, [fileName]: false }));
                            }
                          } else {
                            const errorMessage = 'File name is missing';
                            setError(errorMessage);
                            toast.error(errorMessage);
                          }
                        }}
                        className="Medium_Shade font-bold text-gray-700 py-1 px-4 md:px-8 rounded-full mt-2 inline-block text-center"
                      >
                        {loadingPDFs[caseData?.file_name || ''] ? (
                          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                        ) : (
                          <>Read More</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination flex justify-center items-center mt-4"}
            activeClassName={"active bg-purple-500"}
            pageClassName={"page-item mx-1"}
            pageLinkClassName={"page-link px-3 py-1 border rounded"}
            previousClassName={"page-item mx-1"}
            nextClassName={"page-item mx-1"}
            previousLinkClassName={"page-link px-3 py-1 border rounded"}
            nextLinkClassName={"page-link px-3 py-1 border rounded"}
            breakLinkClassName={"page-link px-3 py-1 border rounded"}
          />
        </div>
      )}

<OverlayComponent
        isOpen={isOverlayOpen}
        onClose={() => setIsOverlayOpen(false)}
        pdfUrl={selectedPdfUrl}
      />
    </div>
  );
};

export default SemanticAI;

