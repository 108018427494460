import { useQuery } from '@tanstack/react-query';
import { fetchSingleUser, fetchSingleNonLoggedUser } from './fetch-single-user';
import { User } from './types';

export const useFetchSingleUser = () => {
  return useQuery<User | null, Error>({
    queryKey: ['user'],   
    queryFn: fetchSingleUser, 
    initialData: null,                         
  });
};


export const useFetchSingleNonLoggedUser = (userId: any) => {
  return useQuery<User | null, Error>({
    queryKey: ['user', userId],   
    queryFn: () => fetchSingleNonLoggedUser(userId),  
    initialData: null,  
  });
};
