import React from 'react';
import Diamond from "../../img/diamond.png";
import Database from "../../img/database.png";
import DocGenImg from "../../img/Landing_Docgen.png";

export default function DocGen() {
  return (
    <section id="docdrafter" className="py-6 md:py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-stretch md:space-x-8">

          {/* Image Container - Visible on desktop, hidden on mobile */}
          <div className="hidden md:flex flex-1 md:w-1/2 justify-center items-stretch">
            <img
              src={DocGenImg}
              alt="AI Doc Drafter"
              className="w-full h-full object-contain rounded-2xl"
            />
          </div>

          {/* Text and Features Container */}
          <div className="flex-1 md:w-1/2 flex flex-col justify-between shadow-xs px-1 md:px-6">
            <div>
              <h3 className="text-center md:text-left text-xl lg:text-4xl font-medium text-gray-900">
                AI Doc Drafter
              </h3>
              {/* Mobile Image - only visible on mobile */}
              <div className="md:hidden mt-4 mb-6">
                <img
                  src={DocGenImg}
                  alt="AI Doc Drafter"
                  className="w-full h-auto object-contain rounded-xl"
                />
              </div>
              <p className="mt-3 text-[12px] text-gray-800 leading-loose">
                AI Doc Drafter is your go-to tool for <span className="font-semibold">generating high-quality legal documents,</span> customized to meet your specific requirements. Whether it’s drafting a contract or creating a legal brief, this feature streamlines the process, saving you time and ensuring precision.
              </p>
            </div>

            {/* Features Section */}
            <div className="flex flex-col sm:flex-row justify-between gap-6 mt-3">
              {/* Feature 1 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Diamond} alt="Reusable Templates" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto" />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Reusable Templates
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  Once you have uploaded a template, you can reuse it as many times as you wish.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left px-4 py-3 md:py-6 rounded-xl">
                <img src={Database} alt="Document Analysis" className="mb-3 self-center md:self-start h-8 w-8 md:h-auto md:w-auto" />
                <h3 className="text-sm md:text-base font-semibold text-gray-900">
                  Document Analysis
                </h3>
                <p className="mt-2 text-[12px] text-gray-800 leading-loose">
                  Compares your document against relevant case law, statutes, and legal precedents, suggesting additional authorities or alternative arguments.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
