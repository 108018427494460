import { useState, useEffect , useCallback} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from "../../data/constants";


const VerifyEmail = () => {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);
  const { VerificationToken } = useParams();

  const navigate = useNavigate();

 

  const verifyUser = useCallback (async () => {
    try {
    await axios.put(`${REACT_APP_BACKEND_URL}/v1/auth/verify/${VerificationToken}`);
      // If the request is successful, set the component state accordingly
      setVerified(true);
      navigate ('/wakili');
      toast.info('Your email address has been successfully verified! You are now all set to explore Lawlyfy.', {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        containerId: 'verification-success'
        });
    } catch (error) {
      // If there's an error, set the error state
      setError(error.response.data|| 'An error occurred while verifying your email.');
    }
  }, [VerificationToken, navigate]);

  useEffect(() => {
    // Call the verifyUser function
    verifyUser();
  }, [VerificationToken, verifyUser]);

  return (
    <div className ='text-center'>
      <h1>Verify your email address</h1>
      {verified ? (
        <p></p>
      ) : (
        <p>{error || 'Verifying your email...'}</p>
      )}
    </div>
  );
};

export default VerifyEmail;
