import axios from 'axios'
import { REACT_APP_BACKEND_URL } from '../data/constants'

//console.log('apiiii====>', REACT_APP_BACKEND_URL)


const API = axios.create({ baseURL: `${REACT_APP_BACKEND_URL}/v1/auth` });


export const loginAPI = (data) => API.post('/login', data);
