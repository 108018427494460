import * as Yup from "yup";

const phoneRegExp = /^(?:\+254|0[17])\d{8}$/;

export const validationSchema = Yup.object({
    Name: Yup.string().required("Username is Required"),
    Email: Yup.string()
      .required("Email is Required")
      .email("Invalid Email Address"),
    PhoneNumber: Yup.string()
      .required("Phone Number is Required")
      .matches(phoneRegExp, "Phone number is not valid."),
    Password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, "Your password must have at least 1 digit character")
      .matches(/[a-z]/, "Your password must have at least 1 lowercase character")
      .matches(/[A-Z]/, "Your password must have at least 1 uppercase character"),
    confirmPassword: Yup.string()
      .required("Please re-type your password")
      .oneOf([Yup.ref("Password")], "Passwords do not match"),
  });