import { REACT_APP_BACKEND_URL } from "../constants";
import axios from 'axios';
import { CaselawSearchParams, CaselawDocument } from './types';

const API_URL = `${REACT_APP_BACKEND_URL}/V1/kenyaCaseLaw/search`;

export const fetchCases = async (searchParams: CaselawSearchParams): Promise<CaselawDocument[]> => {
  try {
    const response = await axios.post<CaselawDocument[]>(API_URL, searchParams);

    const token = localStorage.getItem('token');
    await fetch(`${REACT_APP_BACKEND_URL}/v1/message/updateKeywordCount`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching cases:', error);
    throw error;
  }
};
