import AboutUs2 from "../../img/AboutUs2.png"
import AboutUs1 from "../../img/AboutUs1.jpg"
import Flag from "../../img/flag.png"

export default function Features(){
    return(
        <section id="about" className="py-16 sm:py-24 ">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto text-center">
            <h2 className="text-2xl  md:text-[56px] font-semibold tracking-tight text-gray-900">
              Lawlyfy AI's Unique Features
            </h2>
            <p className="mt-1 md:mt-4 text-xs md:text-sm text-gray-700 leading-loose">
            Lawlyfy AI offers a comprehensive suite of AI-powered research 
            tools tailored to streamline your legal workflows.
            </p>
          </div>

          <ul className="mx-auto mt-2 md:mt-12 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-16 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-4">
            <li className="rounded-3xl shadow-lg shadow-gray-900/5 bg-white overflow-hidden hover:bg-slate-50 transition delay-300">
              <div className="p-3 md:p-6">
                <h3 className="text-base lg:text-lg mt-3 font-semibold text-gray-900">
                  Wakili Chat
                </h3>
                <p className="mt-3 text-xs md:text-sm text-gray-800 leading-loose ">
                Wakili Chat is your AI-powered legal assistant, built to serve 
                as a co-pilot in legal research and case preparation.
                </p>

                <div className="py-2 md:py-6">
                  <a
                    href="#wakilichat"
                    className="text-xs md:text-sm font-medium text-gray-900 delay-150 underline hover:font-bold hover:delay-[0ms]"
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>

              <div>
                <img src={AboutUs1} alt="Wakili Chat" />
              </div>
            </li>

            <li className="rounded-3xl shadow-lg shadow-gray-900/5 bg-white overflow-hidden hover:bg-slate-50 transition delay-300">
              <div className="p-3 md:p-8">
                <h3 className="text-base lg:text-lg mt-3 font-semibold text-gray-900">
                  Chat with My Docs
                </h3>
                <p className="mt-3 text-xs md:text-sm text-gray-800 leading-loose">
                Seamlessly conduct research across your own documents, extract insights, 
                and draft documents in real time. 
                </p>

                <div className="py-2 md:py-4">
                  <a
                    href="#chatwithmydocs"
                    className="text-xs md:text-sm font-medium text-gray-900 delay-150 underline hover:font-bold hover:delay-[0ms]"
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>

              <div>
                <img src={AboutUs2} alt="Chat with your Docs" />
              </div>
            </li>

            <li className="rounded-3xl shadow-lg shadow-gray-900/5 bg-white overflow-hidden hover:bg-slate-50 transition delay-300">
              <div className="p-3 md:p-8">
                <h3 className="text-base lg:text-lg mt-3 font-semibold text-gray-900">
                  Kenya Law 
                </h3>
                <p className="mt-3 text-xs md:text-sm text-gray-800 leading-loose">
                Conduct intuitive, AI-powered searches across Kenya’s  <span className='font-semibold'>Case Law, the Kenya Gazette, Laws of Kenya, and the Cause List. </span>
                </p>

                <div className="py-2 md:py-4">
                  <a
                    href="#kenyalaw"
                    className="text-xs md:text-sm font-medium text-gray-900 delay-150 underline hover:font-bold hover:delay-[0ms]"
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>

              <div>
                <img src={Flag} alt="Doc Gen" />
              </div>
            </li>

            <li className="rounded-3xl shadow-lg shadow-gray-900/5 bg-white overflow-hidden hover:bg-slate-50 transition delay-300">
              <div className="p-3 md:p-8 md:px-6">
                <h3 className="text-base lg:text-lg mt-3 font-semibold text-gray-900">
                  AI Doc Drafter
                </h3>
                <p className="mt-3 text-xs md:text-sm text-gray-800 leading-loose">
                AI Doc Drafter is your go-to tool for generating high-quality legal documents, 
                customized to meet your specific requirements. 
                </p>

                <div className="py-2 md:py-4">
                  <a
                    href="#docdrafter"
                    className="text-xs md:text-sm font-medium text-gray-900 delay-150 underline hover:font-bold hover:delay-[0ms]"
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>

              <div>
                <img src={AboutUs2} alt="Doc Repo" />
              </div>
            </li>
          </ul>
        </div>
      </section>

    )
}