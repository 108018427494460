import { useState } from "react";
import Logo2 from "../../img/Logo2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export default function LandingHeader() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <header className="sticky top-0 z-50 bg-white shadow-md py-4 md:py-0">
      <nav>
        <div className="mx-auto px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between items-center pt-6 max-w-7xl">
          <div className="relative z-10 flex items-center gap-16 flex-grow">
            <div className="flex items-center">
              <a href="/">
                <img src={Logo2} alt="Lawlify Logo" className="h-12 lg:h-14 mr-2" />
              </a>
            </div>

            <div className="hidden lg:flex lg:gap-10 font-semibold">
              <a href="#about" className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300">
                Features
              </a>
              <a href="#pricing" className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300">
                Pricing
              </a>
              <a href="#about" className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300">
                About Us
              </a>
              <a href="#blog" className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300">
                Blog
              </a>
              <a href="#contact" className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors ease-in-out delay-150 hover:text-gray-900 hover:font-bold duration-300">
                Contact
              </a>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <a
              className="inline-flex justify-center rounded-full py-2 px-3 text-xs font-semibold outline-2 outline-offset-2 transition-colors bg-gradient-to-tr from-[#6ddcff] to-[#7f60f9] text-white hover:bg-gray-900 active:bg-gray-800 hidden lg:block"
              href="/login"
            >
              Start Free Trial
            </a>
            <FontAwesomeIcon icon={faUser} className="hidden md:block" />

            <div className="lg:hidden flex items-center gap-4">
              <a
                href="/register"
                className="text-xs inline-block px-4 py-2 rounded-full bg-gradient-to-tr from-[#6ddcff] to-[#7f60f9] text-white font-semibold hover:bg-black"
              >
                Start Free Trial
              </a>
              <button
                className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900"
                aria-label="Toggle site navigation"
                type="button"
                aria-expanded={isOpen}
                onClick={toggleMenu}
              >
                <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className="h-6 w-6">
                  <path
                    d={isOpen ? "M17 14l-5-5-5 5" : "M5 6h14M5 18h14M5 12h14"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"></div>
        )}

        {isOpen && (
          <div className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20">
            <div className="space-y-4">
              <a
                className="block text-base leading-7 tracking-tight text-gray-700"
                href="#about"
                onClick={handleLinkClick}
              >
                Features
              </a>
              <a
                className="block text-base leading-7 tracking-tight text-gray-700"
                href="#pricing"
                onClick={handleLinkClick}
              >
                Pricing
              </a>
              <a
                className="block text-base leading-7 tracking-tight text-gray-700"
                href="#about"
                onClick={handleLinkClick}
              >
                About Us
              </a>
              <a
                className="block text-base leading-7 tracking-tight text-gray-700"
                href="#blog"
                onClick={handleLinkClick}
              >
                Blog
              </a>
              <a
                className="block text-base leading-7 tracking-tight text-gray-700"
                href="#contact"
                onClick={handleLinkClick}
              >
                Contact
              </a>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}