import React, { useState } from "react";
import CitationView from "./(components)/CitationView/page";
import SideBarCitation from "./(components)/SideBarCitation/page";
import { Citations} from "../../data/chats/types";

interface CitationsViewProps {
  selectedCitation: Citations | null;
  setSelectedCitation: (citation: Citations | null) => void;
}

const Citation: React.FC<CitationsViewProps> = () => {
  const [selectedCitation, setSelectedCitation] = useState<Citations | null>(
    null
  );

  return (
    
    <div className="min-h-screen max-h-screen overflow-hidden flex flex-col md:flex-row">
      <SideBarCitation setSelectedCitation={setSelectedCitation} />
      <CitationView selectedCitation={selectedCitation} />
    </div>
  );
};

export default Citation;
